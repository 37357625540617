import { isEmpty } from './index';

// export const required = value => (value && value.length ? true : 'ต้องใส่ข้อมูล')

export const required = value => (value === 0 || !!value ? true : 'ต้องใส่ข้อมูล')

export const requiredIf = (value, target, expectedValue) => () => {
  if (target == expectedValue)
    return required(value)

  return true
}

export const emailValidator = value => {
  if (isEmpty(value)) {
    return true
  }

  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  if (Array.isArray(value)) {
    return value.every(val => re.test(String(val)))
  }

  return re.test(String(value)) || 'รูปแบบอีเมลไม่ถูกต้อง'
}

export const passwordValidator = password => {
  /* eslint-disable no-useless-escape */
  const regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()]).{8,}/
  /* eslint-enable no-useless-escape */
  const validPassword = regExp.test(password)

  return (
    // eslint-disable-next-line operator-linebreak
    validPassword ||
    'ต้องประกอบไปด้วยตัวอักษรตัวใหญ่ ตัวอักษรตัวเล็ก อักขระพิเศษ และ ตัวเลข อย่างน้อยแบบละ 1 ตัว และต้องมีความยาวมากกว่า 8 ตัวอักษร'
  )
}

export const confirmedValidator = (value, target) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  value === target || 'รหัสผ่านไม่ตรงกัน'

export const between = (value, min, max) => () => {
  const valueAsNumber = Number(value)

  return (Number(min) <= valueAsNumber && Number(max) >= valueAsNumber) || `ต้องใส่ตัวเลขนะหว่าง ${min} ถึง ${max}`
}

export const integerValidator = value => {
  if (isEmpty(value)) {
    return true
  }

  if (Array.isArray(value)) {
    return value.every(val => /^-?[0-9]+$/.test(String(val)))
  }

  return /^-?[0-9]+$/.test(String(value)) || 'รองรับเฉพาะจำนวนเต็มเท่านั้น'
}

export const numberValidator = value => {
  if (isEmpty(value)) {
    return true
  }

  if (Array.isArray(value)) {
    return value.every(val => !isNaN(val))
  }

  return !isNaN(value) || 'รองรับเฉพาะตัวเลขเท่านั้น'
}

export const regexValidator = (value, regex) => {
  if (isEmpty(value)) {
    return true
  }

  let regeX = regex
  if (typeof regeX === 'string') {
    regeX = new RegExp(regeX)
  }

  if (Array.isArray(value)) {
    return value.every(val => regexValidator(val, { regeX }))
  }

  return regeX.test(String(value)) || 'The Regex field format is invalid'
}

export const alphaValidator = value => {
  if (isEmpty(value)) {
    return true
  }

  // const valueAsString = String(value)

  return /^[A-Z]*$/i.test(String(value)) || 'รองรับเฉพาะตัวอักษรเท่านั้น'
}

export const urlValidator = value => {
  if (value === undefined || value === null || value.length === 0) {
    return true
  }
  /* eslint-disable no-useless-escape */
  const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/

  return re.test(value) || 'รูปแบบของ URL ไม่ถูกต้อง'
}

export const lengthValidator = (value, length) => {
  if (isEmpty(value)) {
    return true
  }

  value = String(value)

  return value.length === length || `ความยาวต้องเท่ากับ ${length} ตัวอักษร`
}

export const minLengthValidator = (value, length) => {
  if (isEmpty(value)) {
    return true
  }

  value = String(value)

  return value.length >= length || `ความยาวต้องไม่น้อยกว่า ${length} ตัวอักษร`
}

export const maxLengthValidator = (value, length) => {
  if (isEmpty(value)) {
    return true
  }

  value = String(value)

  return value.length <= length || `ความยาวต้องไม่เกิน ${length} ตัวอักษร`
}

export const minValueValidator = (value, minValue) => {
  if (isEmpty(value)) {
    return true
  }

  return value >= minValue || `ต้องมีค่าไม่น้อยกว่า ${minValue}`
}

export const maxValueValidator = (value, maxValue) => {
  if (isEmpty(value)) {
    return true
  }

  return value <= maxValue || `ต้องมีค่าไม่เกิน ${maxValue}`
}

export const alphaDashValidator = value => {
  if (isEmpty(value)) {
    return true
  }

  const valueAsString = String(value)

  return /^[0-9A-Z_-]*$/i.test(valueAsString) || 'รูปแบบตัวอักษรไม่ถูกต้อง'
}

export const duplicateValidator = (value, array, currentIndex, comparator = 'id') => {
  if (isEmpty(value)) {
    return true
  }

  for (let i = 0; i < array.length - 1; i++) {
    if (currentIndex == i) continue;

    if (typeof value == 'object' && array[i][comparator] == value[comparator]) return 'ข้อมูลห้ามซ้ำ'

    if (typeof value != 'object' && array[i] == value) return 'ข้อมูลห้ามซ้ำ'
  }

  return true
}

export const dimensionValidator = ({height, width, length}, { maxHeight, maxwidth, maxLength, maxSum }) => {

  if (height > maxHeight) return `ต้องมีความสูงไม่เกิน ${maxHeight}`

  if (width > maxwidth) return `ต้องมีความกว้างไม่เกิน ${maxwidth}`

  if (length > maxLength) return `ต้องมีความยาวไม่เกิน ${maxLength}`

  if (height + width + length > maxSum) return `ผลรวมของกว้างยาวสูงต้องไม่เกิน ${maxSum}`

  return true
}

export const weightMinValidator = (value, minValue) => {
  if (isEmpty(value)) {
    return true
  }

  return value >= minValue || `ต้องมีน้ำหนักไม่น้อยไปกว่า ${maxValue}`
}

export const weightMaxValidator = (value, maxValue) => {
  if (isEmpty(value)) {
    return true
  }

  return value <= maxValue || `ต้องมีน้ำหนักไม่เกิน ${maxValue}`
}
